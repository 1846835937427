<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 w-auto">
        13th Month Pay
      </h1>
      <div class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" v-if="loadingData">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="px-3">
        <card class="p-5 mt-6">
          <div class="border rounded border-romanSilver border-dashed p-3">
            <p class="font-bold mb-8">Pay Run Information</p>
            <div class="flex w-full gap-3">
              <card class="p-3 w-52">
                <h6 class="uppercase mb-2 font-bold text-sm text-chartPurple">
                  Pay run Type
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.payType }}
                </h6>
              </card>
              <card class="p-3 ml-2">
                <h6 class="uppercase font-bold text-sm text-blueCrayola">
                  Pay Details
                </h6>
                <div class="flex">
                  <div class="flex">
                    <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                      Title:
                    </h6>
                    <span
                      class="px-2 py-1 ml-2 mt-1 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                     "
                    >
                    {{ payrunData.payType }}
                    </span>
                  </div>

                  <div class="flex">
                    <h6 class="font-semibold mt-2 text-sm text-jet uppercase">
                      Pay frequency:
                    </h6>
                    <span
                      class="px-2 py-1 ml-2 mt-1 mr-4 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                    {{ payrunData.payFrequency }}
                    </span>
                  </div>
                  <div class="flex">
                    <h6 class="font-semibold text-sm mt-2 text-jet uppercase">
                      Pay Period:
                    </h6>
                    <span
                      class="px-2 py-1 ml-2 mt-1 text-blueCrayola capitalize font-semibold text-sm"
                      style="
                        background: rgba(33, 118, 255, 0.15);
                        border-radius: 5px;
                      "
                    >
                    {{ payrunData.payPeriod }}
                    </span>
                  </div>
                </div>
              </card>
              <card class="w-auto p-2">
                <p class="uppercase text-flame mt-2 font-extrabold text-sm">
                  Created at
                </p>
                <p class="text-sm uppercase">
                </p>
              </card>
            </div>
          </div>

          <div class="flex justify-between mt-5">
            <div class="flex gap-5 flex-1">
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  Due Date
                </p>
                <p class="text-lg text-darkPurple font-bold uppercase">
                </p>
              </card>
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  No. of Employees
                </p>
                <p class="text-lg text-darkPurple font-bold uppercase">
                  {{ totalEmployees }}
                </p>
              </card>
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  Total Pay
                </p>
                <p class="text-lg text-darkPurple font-bold uppercase">
                  {{ convertToCurrency(totalNetPay) }}
                </p>
              </card>
            </div>
            <div class="flex items-center ml-4">
              <Button
                background-color="none"
                class="text-white mr-5 bg-dynamicBackBtn rounded"
                border="1px solid #F15A29"
                @click="submitPay('review')"
                :disabled="checkboxes.length === 0"
              >
                Submit Payrun
              </Button>
              <Button
                class="bg-white mr-3 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                style-name="width:inherit"
                :disabled="checkboxes.length === 0"
                @click="submitPay('draft')"
              >
                Submit as Draft
              </Button>
            </div>
          </div>
          <div class="border w-full bg-romanSilver mt-8" />
        </card>


        <card class="mt-5">
          <sTable
            :headers="headers"
            :items="tableData"
            style="width:100%; height:auto"
            class="w-full"
            aria-label="table"
            :loading="false"
            :has-checkbox="true"
            @rowSelected="processEmployees"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.name" class="flex items-center">
                <span v-if="item.photo">
                <img
                  class=""
                  :src="item.data.photo"
                  alt="photo"
                  v-if="item.data.photo"
                  style="height: 30px; width: 30px; border-radius: 5px"
                />
                </span>
                <div
                  style="height: 35px; width: 35px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-semibold pt-2"
                  v-else
                >
                  {{ $getInitials(`${item.data.name}`) }}
                </div>
              <div
                class="tw-font-bold flex flex-col ml-3"
                style="color: #525252"
              >
                {{ item.data.name }}
            </div>
            </div>

              <span v-else-if="item.grossPay" class="tw-text-center">
                {{
                  !item.data.grossPay
                    ? "---"
                    : convertToCurrency(item.data.grossPay)
                }}
              </span>
              <span v-else-if="item.monthlyPAYE" class="tw-text-center">
                {{
                  item.data.monthlyPAYE
                    ? convertToCurrency(item.data.monthlyPAYE)
                    : convertToCurrency(0)
                }}
              </span>
              <span v-else-if="item.employeePension" class="tw-text-center">
                {{
                  item.data.employeePension
                    ? convertToCurrency(item.data.employeePension)
                    : convertToCurrency(0)
                }}
              </span>
              <span v-else-if="item.nhfAmount" class="tw-text-center">
                {{
                  item.data.nhfAmount
                    ? convertToCurrency(item.data.nhfAmount)
                    : convertToCurrency(0)
                }}
              </span>
              <span v-else-if="item.netPay" class="grosspay">
                {{ convertToCurrency(item.data.netPay) }}
              </span>
            </template>
          </sTable>
        </card>


        </div>

        </div>
        </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Card from "@/components/Card";
import Button from "@/components/Button";
// import ScrollContainer from "@/components/ScrollContainer";
// import Menu from "@/components/Menu";

export default {
  name: 'ViewEmployees',
  components: {
    BackButton,
    Card,
    // ScrollContainer,
    Button,
    Breadcrumb,
   // Menu,
    STable,
  },

  data() {
    return {
      breadcrumbs: [
        { disabled: false, text: "Payroll", href: "Payroll", id: "Payroll" },
        { disabled: false, text: "Payrun", href: "Payrun", id: "Payrun" },
        {
          disabled: false,
          text: "Off-Cycle Pay",
          href: "Off-Cycle Pay",
          id: "Off-Cycle",
        },
        {
          disabled: false,
          text: "13th Month Pay",
          href: "13th Month Pay",
          id: "13th Month Pay",
        },
      ],
      checkboxes: [],
      loadingData: true,
      payrunData: {},
      totalEmployees: '',
      totalPay: 0,
      headers: [
        { title: "", value: "photo", },
        { title: "Employee", value: "name", },
        { title: "Gross Pay", value: "grossPay", },
        { title: "Paye", value: "monthlyPAYE", },
        { title: "Pension", value: "employeePension", },
        { title: "NHF", value: "nhfAmount", },
        { title: "Net Pay", value: "netPay", },
      ],
      tableData: [],
      processedEmployees: []
    }
    },

  methods: {
    processEmployees(value) {
      this.checkboxes = value;
      this.processedEmployees = [];
      this.payrunData.year = '';

      this.checkboxes.forEach((checkbox) => {
        this.processedEmployees.push(checkbox.employeeId);
        this.payrunData.year = checkbox.year;
      })
    },

    submitPay(status) {
      const payload = {
        employeeIds: this.processedEmployees,
        createdBy: this.$AuthUser.id,
        orgId: this.$orgId,
        payType: this.payrunData.payType,
        payFrequency: this.payrunData.payFrequency,
        splitPosition: this.payrunData.paySplitPosition,
        month: this.payrunData.month,
        year: this.payrunData.year,
        paymentType: this.payrunData.paymentType,
        type: this.payrunData.type,
        payrollStatus: status
      }

      this.$_getPayrunData(payload).then((response) => {
        this.$toasted.success(response.data.message, {duration: 5000});
        if (status === 'draft') {
          this.$router.push({name : '13thMonth', query: { currentTab: "Drafts" }});
        }
        else {
          this.$router.push({name : '13thMonth', query: { currentTab: "Under Review" }});
        }
      })
    }

   },

    mounted() {
      this.$_getThirteenthEmployees(this.$route.query.slug).then((result) => {
        this.loadingData = false;
        this.payrunData.payType = result.data.payrunType;
        this.totalEmployees = result.data.count;
        this.totalNetPay = result.data.totalPay;
        this.payrunData.payFrequency = result.data.payFrequency;
        this.payrunData.payPeriod = result.data.payPeriod;
        this.payrunData.payType = result.data.payType;
        this.payrunData.type = "13th-month";
        this.payrunData.payFrequency = result.data.payFrequency;
        this.payrunData.paySplitPosition = result.data.paySplitPosition;
        this.payrunData.month = result.data.month;
        this.payrunData.paymentType = result.data.paymentType;

        result.data.thirtheenthEmployees.forEach((employees) => {
          this.tableData.push({
            id: employees.userId,
            employeeId: employees.id,
            year: employees.payruns[0] ? employees.payruns[0].payrun.year : null,
            photo: null,
            name: `${employees.fname} ${employees.lname}`,
            grossPay: employees.payruns[0] ? employees.payruns[0].grossEarnings : null,
            monthlyPAYE: employees.payruns[0] ? employees.payruns[0].paye : null,
            employeePension: employees.payruns[0] ? employees.payruns[0].totalPension : null,
            nhfAmount: employees.payruns[0] ? employees.payruns[0].nhfAmount : null,
            netPay: employees.payruns[0] ? employees.payruns[0].netEarnings : null
          })
        });

      });
    }
}
</script>
